<template>
	<section
		:id="slug"
		:class="{
			'mobile': mobileMode,
			'desktop': !mobileMode,
		}"
		class="home-page"
	>
		<div
			class="video-bg-wrapper"
		>
			<img
				:src="resolveRelativeStaticURL(page.image.meta.download_url)"
				:class="{
					'video-ready': videoReady,
				}"
				class="video-placeholder"
				alt=""
			>
			<video
				ref="backgroundVideo"
				:class="{
					'video-ready': videoReady,
				}"
				:src="backgroundSrc"
				class="video-bg"
				playsinline
				muted
				autoplay
				loop
				preload
				@canplaythrough="videoReady = true"
			/>
		</div>

		<h1
			class="message"
			v-html="welcome_message"
		/>

		<button
			v-if="showreelSrc"
			class="showreel-button"
			@click="handleShowreelButtonClick"
		>
			{{ $t('watch_video') }}
		</button>

		<transition name="showreel-fade">
			<modal-overlay
				v-if="showreelSrc"
				v-show="isShowingShowreel"
				ref="showreelOverlay"
				class="showreel-overlay"

				:prevent-body-scroll="isShowingShowreel"
				@touchstart.native="handleShowreelTouchstart"
				@touchmove.native="handleShowreelTouchmove"
				@touchend.native="handleShowreelTouchend"
				@close="toggleShowreel(false)"
			>
				<plyr-wrapper
					ref="showreelPlyr"
					:src="showreelSrc"
					play-button
					class="showreel-plyr"
				>
					<button
						type="button"
						aria-label="Close"
						class="close-showreel"
						@click="toggleShowreel(false)"
					>
						<svg width="75" height="75" viewBox="0 0 75 75" >
							<path fill-rule="evenodd" clip-rule="evenodd" d="M35.9602 37.3744L25 48.3345L26.4142 49.7487L37.3744 38.7886L48.3345 49.7487L49.7487 48.3345L38.7886 37.3744L49.7487 26.4142L48.3345 25L37.3744 35.9602L26.4142 25L25 26.4142L35.9602 37.3744Z" fill="white"/>
						</svg>
					</button>
				</plyr-wrapper>
			</modal-overlay>
		</transition>
	</section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { resolveRelativeStaticURL, cleanOfBlockTags } from '@/utilites.js';
import subpageMixin from './subpage-mixin';
import PlyrWrapper from '@/components/plyr-wrapper';
import ModalOverlay from '@/components/modal-overlay';

/*
 * Regular expressions
 */
const RE_VIDEO_FILE_TYPE = /\.(webm|mp4|ogg)(\?.*)?$/i;
const RE_IS_YOUTUBE = /^https?:\/\/(www\.)?youtu(\.be|be\.com)\//i;
const RE_YOUTUBE_ID = /(\/embed\/|v=|\/\/youtu\.be\/)(\w+)/i;
const RE_IS_VIMEO = /^https?:\/\/(www|player\.)?vimeo\.com\//i;
const RE_VIMEO_ID = /\/\/(vimeo\.com\/|player\.vimeo\.com\/video\/)(\d+)/i;

const SR_SWIPE_TRIGGER_MULTIPLIER = 6;

export default {
	mixins: [
		// adds `pageData` prop and `page` computed value
		subpageMixin,
	],


	name: 'Home',


	data() {
		return {
			videoReady: false,
			isShowingShowreel: false,
			srGestureContext: {
				id: null,
				x: null,
				y: null,
				startX: null,
				startY: null,
			},
		};
	},


	props: {
		// `pageData` via subpage mixin
		current: {
			type: Boolean,
			default: false,
		},
	},


	components: {
		PlyrWrapper,
		ModalOverlay,
	},


	computed: {
		// [page, slug] via subpage mixin
		...mapState('browser', { screenWidth: 'width', screenHeight: 'height' }),
		...mapGetters('browser', ['mobileMode']),

		welcome_message() {
			return cleanOfBlockTags(this.page.welcome_text);
		},

		backgroundSrc() {
			return this.mobileMode
				? this.page.backend_video_mobile_file.meta.download_url
				: this.page.backend_video_file.meta.download_url;
		},

		showreelSrc() {
			if (! (this.page.video_file || this.page.video_link)) return null;
			return this.page.video_file || this.page.video_link;
		},

		showreelPlyr() {
			return this.$refs.showreelPlyr.player || null;
		},
	},



	methods: {
		resolveRelativeStaticURL,

		showreelPlay() {
			if (this.showreelPlyr) return this.showreelPlyr.play();
		},


		showreelPause() {
			if (this.showreelPlyr) return this.showreelPlyr.pause();
		},


		toggleShowreel(isShowingShowreel) {
			if (typeof isShowingShowreel === 'undefined') isShowingShowreel = !this.isShowingShowreel;

			if (isShowingShowreel) {
				this.$refs.backgroundVideo.pause();
				this.showreelPlay();
				// this.focusOnShowreel();

				if (this.mobileMode) this.showreelPlyr.fullscreen.enter();
				this.isShowingShowreel = true;
			} else {
				this.$refs.backgroundVideo.play();
				this.showreelPause();
				if (window.document.fullscreen) this.showreelPlyr.fullscreen.exit();
				this.isShowingShowreel = false;
			}

		},


		handleShowreelTouchstart(event) {
			if (this.srGestureContext.id !== null) return null;
			const touch = event.changedTouches[0]

			this.srGestureContext.id = touch.identifier;
			this.srGestureContext.x = touch.screenX;
			this.srGestureContext.y = touch.screenY;
			this.srGestureContext.startX = touch.screenX;
			this.srGestureContext.startY = touch.screenY;
		},

		handleShowreelTouchmove(event) {
			if (this.srGestureContext.id === null) return null;
			const touch = [].find.call(event.changedTouches, t => t.identifier === this.srGestureContext.id);
			if (!touch) return null;


			this.srGestureContext.x = touch.screenX;
			this.srGestureContext.y = touch.screenY;
		},

		handleShowreelTouchend(event) {
			if (this.srGestureContext.id === null) return null;
			const touch = [].find.call(event.changedTouches, t => t.identifier === this.srGestureContext.id);
			if (!touch) return null;

			this.srGestureContext.x = touch.screenX;
			this.srGestureContext.y = touch.screenY;

			const dX = Math.abs(this.srGestureContext.x - this.srGestureContext.startX);
			const dY = Math.abs(this.srGestureContext.y - this.srGestureContext.startY);

			const actualDelta = dX > dY ? dX / this.screenWidth * this.screenHeight : dY;

			if (actualDelta > this.screenHeight / SR_SWIPE_TRIGGER_MULTIPLIER) {
				if (window.document.fullscreen) this.showreelPlyr.fullscreen.exit();
				else this.toggleShowreel(false);
			}

			this.srGestureContext.id = null;
			this.srGestureContext.x = null;
			this.srGestureContext.y = null;
			this.srGestureContext.startX = null;
			this.srGestureContext.startY = null;
		},


		handleShowreelButtonClick() {
			this.toggleShowreel();
			this.focusOnShowreel();
		},


		/**
		 * Omg... Some hackky way
		 *
		 * Probably I try to trigger focus() on an element with 'display' setted to 'none' so it didn't work
		 */
		focusOnShowreel() {
			this.$nextTick(() => {
				const overlay = this.$refs.showreelOverlay;
				// overlay is a component
				const el = overlay.$el;
				el.focus();
				if (document.activeElement !== el) {
					setTimeout(() => {
						el.focus();
					}, 150);
				}
			});
		},
	},

	mounted() {
		this.showreelPlyr.on('exitfullscreen', event => {
			if (this.mobileMode) this.toggleShowreel(false);
		});
	}
};
</script>

<style lang="sass" scoped>
// See and/or edit all the vars here: `src/assets/styles/_vars.sass`
// Thank you :*

.home-page
	position: relative
	display: flex
	flex-direction: column
	align-items: flex-start
	justify-content: center
	box-sizing: border-box
	overflow: hidden
	width: 100%
	min-height: 100vh
	min-height: calc(100 * var(--init-vh, 1vh))
	box-sizing: border-box

	&.mobile
		@extend %page-mobile
		@extend %page-mobile-standalone

	&.desktop
		@extend %page-desktop
		@extend %page-desktop-standalone

.video-bg-wrapper
	position: absolute
	z-index: -1
	display: flex
	align-items: center
	justify-content: center
	height: 100%
	width: 100%
	top: 0
	right: 0
	bottom: 0
	left: 0
	overflow: hidden
	background-color: $clr__dark

.video-placeholder,
.video-bg
	display: block
	position: absolute
	width: 100%
	height: 100%
	margin: 0
	top: 0
	right: 0
	bottom: 0
	left: 0
	object-fit: cover
	pointer-events: none
	transition: opacity .2s ease

.video-placeholder
	opacity: .8

	&.video-ready
		opacity: 0

.video-bg
	opacity: 0

	&.video-ready
		opacity: .8


.message
	color: #ffffff
	transition: opacity .2s ease, transform .2s ease
	pointer-events: none
	margin: 0

	&:not(:last-child)
		margin-bottom: 1em

	.mobile &
		@extend %h1-mobile

	.desktop &
		@extend %h1-desktop

	&.hide
		opacity: 0
		transform: translate3d(0, -25%, 0)




.showreel-overlay
	z-index: 7
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center

	.desktop &
		background-color: rgba(#222222, 0.7)
		padding: 80px

	.mobile &
		background-color: transparent
		padding: 25px


.showreel-plyr
	will-change: opacity, transform
	position: relative
	z-index: 0

	.mobile &
		position: fixed
		width: 100%
		max-width: calc((100vh - 50px) / 9 * 16)
		top: -100vh


	.desktop &
		width: calc(100% - 160px)
		max-width: calc((100vh - 160px) / 9 * 16)


.close-showreel
	@extend %clean-button
	position: absolute
	top: 0
	right: 0
	cursor: pointer
	z-index: 1

.showreel-button
	@extend %button
	--height: 60px
	cursor: pointer
	color: invert($clr__def)

.showreel-fade-enter-active
	overflow-y: hidden
	transition: opacity .3s ease-in-out

	.showreel-plyr
		transition: transform .2s cubic-bezier(.25,0,0,1) .1s, opacity .2s ease .1s


.showreel-fade-leave-active
	overflow-y: hidden
	transition: opacity .2s ease-in-out .2s

	.showreel-plyr
		transition: transform .2s ease-in, opacity .2s ease


.showreel-fade-enter,
.showreel-fade-leave-to
	opacity: 0

	.showreel-plyr
		opacity: 0
		transform: translate3d(0, 3%, 0) scale3d(.95, .95, 1)
</style>
