<template>
  <section
    class="modal-overlay"
    ref="overlay"
    tabindex="-1"
    v-prevent-body-scroll="preventBodyScroll"

    @mousedown="onMousedown"
    @touchstart="onTouchstart"
    @mouseup.self="onMouseup"
    @touchend.self="onTouchend"
    @keyup.esc="close"
  >
    <slot />
  </section>
</template>


<script>
import { directive as PreventBodyScroll } from '@/plugins/prevent-body-scroll.js';

export default {
  name: 'ModalOverlay',

  directives: {
    preventBodyScroll: PreventBodyScroll,
  },

  props: {
  	preventBodyScroll: Boolean,
  },

  data() {
    return {
      isOverlayMousedowned: false,
      isOverlayTouchstarted: false,
    };
  },


  methods: {
    /**
     * Mousedown handler
     * save isMousedowned
     * @param {MouseEvent} event
     */
    onMousedown(event) {
      this.isOverlayMousedowned = event.target === this.$refs.overlay;
      this.isOverlayTouchstarted = false;
    },


    /**
     * Mouseup handler
     * check if previously mousedowned and fire close if is
     */
    onMouseup() {
      if (this.isOverlayMousedowned) {
        this.close();
      }
    },


    /**
     * Touchstart handler
     * save isTouchstarted
     * @param {MouseEvent} event
     */
    onTouchstart(event) {
      this.isOverlayTouchstarted = event.target === this.$refs.overlay;
      this.isOverlayMousedowned = true;
    },


    /**
     * Touchend  handler
     * check if previously touchstarted and fire close if is
     */
    onTouchend() {
      if (this.isOverlayTouchstarted) {
        this.close();
      }
    },


    /**
     * Close modal
     * Emits `close` event
     */
    close() {
      this.$emit('close');
    },
  },


  mounted() {
    this.$refs.overlay.focus();
  },
};
</script>

<style lang="sass" scoped>
.modal-overlay
  position: fixed
  z-index: 9001
  top: 0
  right: 0
  bottom: 0
  left: 0
  transform: translateZ(0)
  background-color: rgba(#000000, .3)
  outline: none
</style>
