var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"home-page",class:{
		'mobile': _vm.mobileMode,
		'desktop': !_vm.mobileMode,
	},attrs:{"id":_vm.slug}},[_c('div',{staticClass:"video-bg-wrapper"},[_c('img',{staticClass:"video-placeholder",class:{
				'video-ready': _vm.videoReady,
			},attrs:{"src":_vm.resolveRelativeStaticURL(_vm.page.image.meta.download_url),"alt":""}}),_c('video',{ref:"backgroundVideo",staticClass:"video-bg",class:{
				'video-ready': _vm.videoReady,
			},attrs:{"src":_vm.backgroundSrc,"playsinline":"","muted":"","autoplay":"","loop":"","preload":""},domProps:{"muted":true},on:{"canplaythrough":function($event){_vm.videoReady = true}}})]),_c('h1',{staticClass:"message",domProps:{"innerHTML":_vm._s(_vm.welcome_message)}}),(_vm.showreelSrc)?_c('button',{staticClass:"showreel-button",on:{"click":_vm.handleShowreelButtonClick}},[_vm._v(" "+_vm._s(_vm.$t('watch_video'))+" ")]):_vm._e(),_c('transition',{attrs:{"name":"showreel-fade"}},[(_vm.showreelSrc)?_c('modal-overlay',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowingShowreel),expression:"isShowingShowreel"}],ref:"showreelOverlay",staticClass:"showreel-overlay",attrs:{"prevent-body-scroll":_vm.isShowingShowreel},on:{"close":function($event){return _vm.toggleShowreel(false)}},nativeOn:{"touchstart":function($event){return _vm.handleShowreelTouchstart($event)},"touchmove":function($event){return _vm.handleShowreelTouchmove($event)},"touchend":function($event){return _vm.handleShowreelTouchend($event)}}},[_c('plyr-wrapper',{ref:"showreelPlyr",staticClass:"showreel-plyr",attrs:{"src":_vm.showreelSrc,"play-button":""}},[_c('button',{staticClass:"close-showreel",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.toggleShowreel(false)}}},[_c('svg',{attrs:{"width":"75","height":"75","viewBox":"0 0 75 75"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M35.9602 37.3744L25 48.3345L26.4142 49.7487L37.3744 38.7886L48.3345 49.7487L49.7487 48.3345L38.7886 37.3744L49.7487 26.4142L48.3345 25L37.3744 35.9602L26.4142 25L25 26.4142L35.9602 37.3744Z","fill":"white"}})])])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }